import * as React from 'react';

import CommonLayout from '../components/home/commonLayout';
import Layout from '../components/layout';
import PharmCorpHeading from '../components/typography/heading';
import SEOTag from '../components/seo/seo';
import jeorgeSignature from '../images/jorge-signature.png';

// markup
const AboutPage = () => {
  return (
    <Layout selectedMenu='about'>
      <SEOTag title='About Us | Pharmcorps' />
      <CommonLayout>
        <PharmCorpHeading
          lightText='A Letter from the'
          boldText='Founder'
          extraClassNames='my-4'
          underLined={true}
        />
        <div className='h-2 mx-auto mb-10'></div>
        <div className='pb-10 text-gray-600  '>
          Can one individual change the world?
          <br />
          <br />
          Personally, I truly believe in the extraordinary potential for any
          single human being to make a lasting, positive impact to society. With
          social media constantly reminding us of the chaotic, dangerous, and
          unjust world we live in, it is becoming more and more difficult for an
          individual to believe they can provide a beacon of hope to the world.
          <br />
          <br />
          As Mr. Rogers would say, “look for the helpers. You will always find
          people who are helping.”
          <br />
          <br />
          I envision PharmCorps as a company full of good-hearted dreamers. The
          kind of people who, no matter how badly they are treated or how unfair
          their life has been, no matter how many failures they’ve experienced
          or mistakes they have made; they continue to stay true to the core
          values that help bring out peace, joy, kindness, and the best of
          humanity. They continue to stay true to themselves and never doubt
          their ability to make life better for everyone around them.
          <br />
          <br />
          Those are the real world-changers, and the people we want to work with
          at PharmCorps.
          <br />
          <br />
          Can you imagine what an entire team of people like this are capable of
          accomplishing?
          <br />
          <br />
          Call me a dreamer if you’d like, but I’m a believer in the goodness of
          humanity. That is why we open our doors to those who care and simply
          want to help others. Sure, becoming the most successful, profitable,
          or groundbreaking company sounds great, but our goal is to reduce as
          much unnecessary suffering in the world as we possibly can. And, as a
          clinician, it only made sense that our journey to change the world
          starts by improving the way we take care of one another.
          <br />
          <br />
          <br />
          Regards,
          <div className='w-1/2 lg:w-1/5 md:w-1/3 sm:w-1/3'>
            <img src={jeorgeSignature} alt='Jorge' className='w-96' />
          </div>
        </div>
      </CommonLayout>
    </Layout>
  );
};

export default AboutPage;
